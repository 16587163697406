import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../components/en';
import SEO from '../components/seo';
import Scroll from '../components/scroll';

import RecentPostsMD from '../components/blog-md/recent-posts-en';
import TeamFeaturedMD from '../components/team-md/team-featured-en';
import AwardsMD from '../components/awards/awards-en';
import FeaturedProductsMD from '../components/products/featured-en';

const IndexPage = (props) => (
  <Layout location={typeof window !== 'undefined' ? location : null}>
    <SEO
      title="Furlan Snowboards - Official website and online shop"
      description="Based in Isère, all our board have a little extra for powder, on top of there initial programs."
      pathname="/en/"
      keywords={['snowboard', 'boards', 'polyvalence']}
    />
    <div className="indexPage">
      {/*
        <header className="masthead">
          <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-11 offset-lg-1 header-content my-auto">
                  <h1 className="mb-4">La polyvalence<br />a son maximum</h1>
                  <div className="row">
                    <div className="col-lg-12 mb-4">
                      <h3>
                        Livraison gratuite en Europe
                        <br />2 jours en France
                      </h3>
                    </div>
                    <div className="col-lg-12 ">
                      <Scroll type="id" element="products">
                        <button className="btn btn-primary btn-lg">Soldes Automne</button>
                      </Scroll>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <blockquote className="blockquote-credit">
            @leo.cryl (Leo Carayol)
          </blockquote>
        </header>
      */}
      <div className="alert alert-secondary" role="alert" style={{marginTop: '20px'}}>
        <center>🎁 <strong>-300€ IF YOU FIND A MATE WHO BUYS A 2ND BOARD WITH YOU!</strong> 🎁<br />🎉 <strong>Code: yepoto</strong> -150€ on each board at checkout! 🎉</center>
      </div>
      <FeaturedProductsMD />
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-10 offset-lg-1 header-content my-auto">
            <h1 className="mb-4"><br /><br /><br />In the news !</h1>
            <div className="row">
              <div className="col-lg-12 mb-4">
                <p>
                Video under: Board Aaron Swartz tribute 163. To some he was a criminal, to us he was a hero for humanity, a rebel. That's what makes him so close to us, his anti-conformist attitude.
                </p>
                <p>
                Board culture means surf, snow, water, concrete. Rebel culture means freedom, style, art, music! The Furlan Family is all about fun, snowboarders, musicians, writers, photographers, film-makers, kids, teens, a little stunt work but not too much, we prefer aesthetics.
                </p>
                <p>
                Snowboarding is a whole. And we're proud to be part of it. Come and join us! 
Furlan Snowboards. <br /><br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="video-background">
        <div className="video-foreground">
          <iframe src="https://www.youtube.com/embed/O3M8QZYRR-M" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
      <section className="cta mt-5">
        <div className="cta-content">
          <div className="container">
            <div className="col-lg-10 offset-lg-2 mb-3">
              <h2>
                Versatile<br />
                Extra Floaters
              </h2>
            </div>
          </div>
          <div className="container-fluid">
            <div className="col-lg-12">
              <AwardsMD />
            </div>
          </div>
        </div>
      </section>
      <blockquote className="blockquote-credit">
        @thereivolution (Shawn Rei) / Clem Nell
      </blockquote>
      <TeamFeaturedMD />
      <hr />
      <RecentPostsMD />
    </div>
  </Layout>
);

export default IndexPage;
